
import { defineComponent } from "vue";
import mixins from "@/mixins/index";

export default defineComponent({
  name: "AltHistoryCard",
  props: ["historyInfo"],
  mixins: [mixins],
  methods: {
    coloredBar(percentage: number) {
      return { "--width": `${percentage}%` };
    },
    whiteBar(percentage: number) {
      return { "--width": `${percentage + 2}%` };
    },
  },
});
