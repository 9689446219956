
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import { VideoCardInfo } from "@/types/models"; // Interface

import CommentSection from "@/components/Video/CommentSection.vue";

var serverUrl = process.env.VUE_APP_SERVER;
var loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "VideoCard",
  components: { CommentSection },
  props: ["classId"],
  mixins: [mixins],
  data() {
    return {
      videoCardInfo: {} as VideoCardInfo,
      data: {
        classId: 1,
        classCategory: "A",
        classTime: 1645876623,
        flagImg: "S003",
        teacherImg: "/static/img/teacher_icon/teacher_ryan.svg",
        classTitle:
          "國外旅遊對話10分鐘上手，實用又有趣的英文！國外旅遊對話10分鐘上手，實用又有趣的英文！國外旅遊對話10分鐘上手，實用又有趣的英文！",
        classDescription:
          "不怕你不會，就怕你不說，開口說練習5分鐘馬上上手的實用旅遊英文實用旅遊英文！",
        classTeacher: "Ryan / 洪振瑋",
        teacherEvaluation: [
          4.0,
          "上課專心，不懂的地方會問到懂為止，比起上一堂課時進步很多！建議你多閱讀英文讀本幫助更快理解語意，希望你再接再勵！",
        ],
        homework: null,
      },
    };
  },
  mounted() {
    // 取得影片資料
    axios
      .get(`${serverUrl}classqrystd/record/${this.classId}/video`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.videoCardInfo = res.data.data.video;
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
  },
});
