
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import { CommentInfo, VideoCardInfo } from "@/types/models";

var serverUrl = process.env.VUE_APP_SERVER;
var loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "VideoCommentSection",
  mixins: [mixins],
  props: ["classId"],
  data() {
    return {
      videoInfo: {} as VideoCardInfo,
      isLoading: true,
      commentInfo: [] as Array<CommentInfo>,
      message: "",
      user: {
        name: "",
        image: "",
      },
    };
  },
  methods: {
    // 送出留言
    postComment() {
      // 前端呈現
      var newComment = {
        goosipContext: this.message, // 取得輸入文字
        goosipTimeStamp: this.currentTime(),
        role: "S",
        userAvatar: this.user.image,
        userName: this.user.name,
      };

      if (this.message !== "") {
        this.commentInfo.push(newComment);
        // 傳送至後端
        axios
          .post(
            `${serverUrl}classqrystd/record/${this.classId}/chat`,
            { context: this.message },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              // console.log(res);
            }
          })
          .catch((error) => {
            console.error(`錯誤： ${error}`);
          });
        // 重置輸入文字
        this.message = "";
      }
    },
  },
  computed: {
    // 關閉留言區
    disableInput() {
      if (this.videoInfo.classDate == undefined) {
        return 0; // 載入中
      } else if (
        this.currentTime() - this.videoInfo.classDate >
        60 * 60 * 24 * 7
      ) {
        return 1; // 關閉
      } else {
        return 2; // 繼續開放留言
      }
    },
  },
  mounted() {
    // 取得使用者資料
    axios
      .get(`${serverUrl}personal`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.user.name = res.data.data.user.name;
          this.user.image =
            res.data.data.user.photo !== null
              ? res.data.data.user.photo
              : "";
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // 取得對話資料
    axios
      .get(`${serverUrl}classqrystd/record/${this.classId}/chat`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.commentInfo = res.data.data.chatRoom;
          this.isLoading = false;
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
    // 取得課程資料
    axios
      .get(`${serverUrl}classqrystd/record/${this.classId}/video`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.videoInfo = res.data.data.video;
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });
  },
});
