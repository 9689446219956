
import { defineComponent } from "vue";
import axios from "axios";

import Template3 from "@/views/templates/Template3.vue";
import VideoCard from "@/components/Video/VideoCard.vue";
import HistoryCard from "@/components/Video/HistoryCard.vue";
import AltHistoryCard from "@/components/Video/AltHistoryCard.vue";
import CommentSection from "@/components/Video/CommentSection.vue";

var serverUrl = process.env.VUE_APP_SERVER;
var loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface HistoryInfo {
  cnName: string;
  foreignName: string;
  photo: string | null;
  title: string;
  type: string;
}

export default defineComponent({
  name: "Video",
  components: {
    Template3,
    VideoCard,
    HistoryCard,
    AltHistoryCard,
    CommentSection,
  },
  data() {
    return {
      classId: this.$route.query.id,
      historyInfo: "no result" as string | Array<HistoryInfo>,
    };
  },
  mounted() {
    // const videoId = this.$route.query.id
    // 取得觀看影片紀錄
    axios
      .get(`${serverUrl}classqrystd/record/video`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.historyInfo = res.data.data.videoRecord;
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // 發API：已點開此影片
  },
});
